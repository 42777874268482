"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CITIZEN_STATUS_FIELDS = void 0;
var entities_1 = require("@common/entities");
var constants_1 = require("../../constants");
exports.CITIZEN_STATUS_FIELDS = [
    {
        name: 'citizen_status',
        type: entities_1.FieldType.Dropdown,
        label: 'What is your citizenship status?',
        enabled: true,
        required: true,
        isConfigurable: false,
    },
    {
        name: 'has_ssn',
        type: entities_1.FieldType.YesNo,
        customType: entities_1.FieldType.YesNo,
        label: 'Do you have a Social Security Number (SSN)?',
        options: constants_1.YES_NO_OPTIONS,
        enabled: true,
        isConfigurable: false,
    },
    {
        name: 'ssn_number',
        type: entities_1.FieldType.Text,
        label: 'Enter your SSN',
        enabled: true,
        required: true,
        isConfigurable: false,
    },
    {
        name: 'ssn_exception',
        type: entities_1.FieldType.Radio,
        label: 'SSN Exception',
        options: constants_1.SSN_EXCEPTION_OPTIONS,
        enabled: true,
        required: true,
    },
    {
        name: 'tracs_id',
        type: entities_1.FieldType.Text,
        label: 'Enter TRACS ID',
        enabled: true,
        required: true,
    },
];
